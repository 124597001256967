const von = {
    sperant_id: 27,
    route:"/von",
    page:"von",
    district:'Santa Catalina',
    whatsapp: ["947302192"],
    titlehead: "Proyecto Von | Departamentos en Santa Catalina",
	metadescription:"La mejor conectividad en Santa Catalina. Departamentos de 1, 2 y 3 dorms Hub desde S/255,900, el mejor enlace entre la ciudad y tu depa propio. Un condominio para disfrutar la vida que siempre has querido rodeado de más de 15 áreas comunes para sumar vivencias. Cotiza aquí",
	metakeywords:"departamentos en Santa Catalina, departamentos en venta en Santa Catalina, inmobiliaria, Santa Catalina, casaideal inmobiliaria, departamentos de 1, 2 y 3 dormitorios, edificio en preventa, departamentos en preventa",
    iziPubKey: {
        prod: "70370416:publickey_UdMIVzQjkfnCWXtQGAiwdzvhOsYgQl5PXuKxscQwrDgw2",
        dev: "70370416:testpublickey_2oLGfavT6dAblPP6FPZvPVNU1a4BaqUITImpgIAt4Kp2C",
    },
    areas:{
        cant:4,
        comunes:[

        ],
        interiores:[
        ],
        exteriores:{
            cantButton:4,
            title:"Avance de obra Febrero",
            desktop:[
                
            ]
        },
        avance:{
            cantButton:4,
            title:"Avance de obra Febrero",
            desktop:[
            ]
        },
        end_project:{
            cantButton:5,
            title:"",
            images:[]
        },
        depa_piloto:[
        ],
        recorrido:"",
        avance:{
            cantButton:4,
            title:"Avance de obra Noviembre",
            desktop:[
            ]
        },
    },
    form:[
        {value:1,title:"1 Dormitorio"},
        {value:2,title:"2 Dormitorios"},
        {value:3,title:"3 Dormitorios"}
    ],
    info:{
        title:"CERCA DE ",subtitle:"TODO",
        entrega:'En Construcción',
        frase:'LA ZONA MÁS ESTRATÉGICA <span class="bold">DE CHORRILLOS</span>',
        pisos:"17",depas:"64",dorms:"2 y 3 Dorms.",ubicacion:"Calle Ontario 200, La Campiña, Chorrillos",precio:"S/304,900",desde:"57m²",hasta:"65m²",
        fachada:require("../../Assets/images/Slider/print/exteriores/FACHADA-NOCHE.webp"),
        fachadaMinified:require("../../Assets/images/Slider/print/exteriores/FACHADA-NOCHE.webp"),
        parrafo:`
            <p class="paragraph">Un cambio que tú y tu familia necesitan, encuentra en Print la exclusividad y calidad que mereces en un barrio accesible.</p>
            <p class="paragraph">Un proyecto diseñado para familias pequeñas que viven a su propio ritmo y para familias grandes que buscan experiencias únicas.</p>
            <p class="paragraph">Hacer de la cotidianidad un espacio de libertad y de tu barrio, una extensión de tu hogar.</p>
            `,
        parrafoArquitectura1:'Print es un condominio que cuenta con 3 torres y una arquitectura elegante con detalles en concreto caravista.',
        brochure:'https://casaideal.com.pe/link/brochure-link.html',
        brochure_mobile:'https://casaideal.com.pe/link/mobile/brochure-link.html',
        arquitectura:'<span class="bold">ARQUITECTURA </span>& DISEÑO',
        // ubicación
        mapa:require("../../Assets/mapas/print.jpg"),
        sitios:[
            {title:"Centro comercial, mercados:",sitio:["Real plaza guardia civil","Metro","Mercado santa rosa","Precio uno ","Plaza Lima Sur"]},
        ]
    },
    slideDepa:[
           
    ],
    atributos:[
        // {icon:"mask edificio",text:"<strong>Departamentos inteligentes</strong>"},
       
        {icon:require("../../Assets/images/iconos/edge.svg").default,text:"Certificación</br> Edge"},
       
    ],
    tipologias:{
        page:"von",
        template_id: 497,
        buttons:[{text:"02",value:2},{text:"03",value:3},{text:"Todos",value:"all"}],
        brand:require('../../Assets/images/proyectos/brand-hub.jpg').default,
        cards: [],
       
    }
}
export default von